import React, { useEffect, useState } from 'react'
import { motion, useScroll, useTransform, useViewportScroll } from "framer-motion"
import { Link, useLocation } from 'react-router-dom';


const DATA = [
  {
    title: 'Anasayfa',
    link: '/'
  },
  {
    title: 'Kurumsal',
    dropDown: [
      {
        title: 'E-KATALOG',
        // link: '/e-katalog',
      },
      {
        title: 'Hakkımızda',
        link: '/kurumsal',
      },
      {
        title: 'Tarihçe',
        link: '/tarihce',
      },
      {
        title: 'Sertifikalarımız',
        link: '/sertifikalarimiz',
      },

    ]

  },

  {
    title: 'Çözümler',

    dropDown: [
      {
        title: 'SIEMENS SIVACON Çözümleri',
        link:'/cozumler/siemens-sivacon-cozumler'
      },
      {
        title: 'Proje Tasarım',
        link:'/cozumler/proje-tasarim'
      },
      {
        title: 'Saha Supervisor Hizmetleri',
        link:'/cozumler/saha-supervizorluk-ve-devreye-alma-test'
      },
      // {
      //   title: 'Şarj İstasyonları',

      // },
    ]
  },

  {
    title: 'Referanslar',
    // link: '/',
    dropDown: [
      {
        title: 'Yurt İçi',
        link:'/referanslar/yurtici',
      },

      {
        title: 'Yurt Dışı',
        link:'/referanslar/yurtdisi',

      }
    ]
  },
  {
    title: 'Medya',
    link:'/medya'
  },
  {
    title: 'İletişim',
    link: '/iletisim'
  },
  {
    title: 'TR',
    // link:'/'
    dropDown: [
      {
        title: 'EN',
        // link:'/'
      },

    ]

  },
]


const Header = () => {
  const [toggle, setToggle] = useState(false)
  const location = useLocation();
  const { scrollY } = useViewportScroll();
  const bgOpacity = useTransform(scrollY, [0, 2 * 16], [0, 1]);
  const bgColor = useTransform(
    scrollY,
    [0, 2 * 16],
    ["rgba(0, 0, 0, 0)", "rgba(255, 255, 255, 1)"]
  );

  const fontSizez = useTransform(
    scrollY,
    [0, 2 * 16],
    ["16px", "14px"]
  );


  const fontSizeMobile = useTransform(
    scrollY,
    [0, 2 * 16],
    ["20px", "18px"]
  );

  const textColor = useTransform(
    scrollY,
    [0, 2 * 16],
    ["rgba(255, 255, 255, 1)", "rgba(0, 0, 0, 1)"]
  );

  const toggleMenuIconColor = useTransform(
    scrollY,
    [0, 2 * 16],
    ["rgba(255, 255, 255, 1)", "rgba(0, 154, 162, 1)"]
  );

  const headerHeight = useTransform(
    scrollY,
    [0, 2 * 16],
    ["6rem", "6rem"]
  );

  const iconFilterBrightnes = useTransform(
    scrollY,
    [0, 2 * 16],
    [0, 1]
  );


  const iconFilterInvert = useTransform(
    scrollY,
    [0, 2 * 16],
    [1, 0]
  );


  useEffect(() => {
    setToggle(false)
  }, [location])
  



  console.log(location)





  return (
    <header className="flex justify-center   fixed w-full  h-24  left-0 z-[100] ">
      <motion.div style={{ background: bgColor }} className="w-full px-4  h-24  fixed  lg:px-28 p-0 flex justify-between py-4 lg:py-2  3xl:container  transition-all duration-500">

        <div className="flex py-2 lg:py-1 relative z-100 w-full">
          <Link to={'/'} className='h-full block'>
            <img   className='h-full mr-3'   src="/image/header/1.svg" /></Link>
          <img className='h-full mr-1' src="/image/header/2.svg"  />
          <img className='h-full ' src="/image/header/3.svg"  />
        </div>



        <div onClick={() => setToggle(!toggle)} className='flex lg:hidden h-full w-10   flex-col justify-between py-[1.1rem]  items-center overflow-hidden cursor-pointer'>
          <motion.div style={{background:toggleMenuIconColor}} className={`h-px w-full transition-all duration-500 bg-white ${toggle ? 'translate-y-4' : 'translate-y-0'} ${toggle ? 'rotate-45' : 'rotate-0'}`}></motion.div>
          <div style={{background:toggleMenuIconColor}} className='flex  h-px w-full'>
            <motion.div style={{background:toggleMenuIconColor}} className={`h-px w-5 bg-white transform ${toggle ? '-translate-x-5' : 'translate-x-0'} transition-all duration-500`}></motion.div>
            <motion.div style={{background:toggleMenuIconColor}} className={`h-px w-5 bg-white transform ${toggle ? 'translate-x-5' : 'translate-x-0'} transition-all duration-500`}></motion.div>
          </div>
          <motion.div style={{background:toggleMenuIconColor}} className={`h-px w-full bg-white ${toggle ? '-translate-y-4' : 'translate-y-0'} transition-all duration-500 ${toggle ? '-rotate-45' : 'rotate-0'}`}></motion.div>
        </div>



        <nav className="lg:flex items-center hidden ">

          <ul className='flex  h-full'>
            {
              DATA.map((item, index) => {
                return (
                  <li key={index} className='group cursor-pointer relative flex  items-center hover:bg-[rgba(0,0,0,0.7)] px-4 transition-all duration-500 '>
                    <Link to={item.link}><motion.h6 style={{ fontSize: fontSizez, color: textColor }} className={`${item.link === location.pathname ? '!text-tedakblue' : ''} flex  group-hover:!text-tedakblue`}>{item.title}{item.dropDown && (<motion.img style={{ filter: ` invert(${iconFilterInvert})` }} className='ml-2 transform group-hover:rotate-90 transition-all duration-500 ' src='/image/header/miniHook.svg' />)}</motion.h6></Link>
                    {
                      item.dropDown &&
                      <ul className='absolute opacity-0 top-[5.5rem] left-0 invisible group-hover:visible  bg-[rgba(0,0,0,0.7)]   shadow-lg text-white group-hover:opacity-100  overflow-hidden   transition-all duration-500 w-max'>
                        {
                          item.dropDown.map((item, index) => {
                            return (
                              <li key={index} className='group/innerDropDown '>
                                <Link to={item.link}><motion.h6 style={{ fontSize: fontSizez }} className={` px-4 py-2 flex group-hover/innerDropDown:!text-tedakblue`}> {item.title}{item.dropDown && (<img className='ml-2 transform group-hover/innerDropDown:rotate-90 transition-all duration-500' src='/image/header/miniHook.svg' />)}</motion.h6></Link>

                                {
                                  item.dropDown &&
                                  <ul className='h-0 group-hover/innerDropDown:h-auto bg-transparent  overflow-hidden transition-all duration-500  '>
                                    {
                                      item.dropDown.map((item, index) => {
                                        return (
                                          <Link to={item.link}><li key={index} className='hover:text-tedakblue'>
                                            <motion.h6 style={{ fontSize: fontSizez }} className='  pl-5 '> {item.title}</motion.h6>
                                          </li>
                                          </Link>
                                        )
                                      })
                                    }
                                  </ul>
                                }

                              </li>
                            )
                          })
                        }
                      </ul>
                    }
                  </li>
                )
              })
            }
          </ul>
        </nav>


        {toggle &&
          <nav className={`absolute top-0 left-0 h-screen overflow-auto w-full   -z-10 pt-24 px-5 transform  transition-all duration-500 ${toggle?'opacity-100':'opacity-0'}`}>

            <div className='h-screen absolute top-0 left-0 overflow-hidden z-20'><img className='' src='/image/menu/menu-banner.jpg' /></div>
            <ul className='w-full z-50 relative  '>
              {
                DATA.map((item, index) => {
                  return (
                    <li key={index} className='w-full group'>

                      <div className='flex items-center h-16 '>
                        <Link className='' to={item.link} onClick={(e)=>item.link?'':e.preventDefault()} >
                          <motion.h6  className={' w-24 text-white text-xl'}>{item.title}</motion.h6>
                        </Link>
                        <div className='w-full '>
                          {item.dropDown && (<div className='w-full block '><motion.img src='/image/header/miniHook.svg' /></div>)}
                        </div>
                      </div>
                      {
                        item.dropDown &&
                        <ul className=' h-0 opacity-0 group-hover:opacity-100 group-hover:h-auto overflow-hidden transition-all duration-500 pl-[10%] '>
                          {
                            item.dropDown.map((item, index) => {
                              return (
                                <li key={index} className='group/innerDropDown py-1'>
                                  <div className='flex items-center '>
                                    <Link to={item.link} className=''>
                                      <motion.h6  className={'text-nowrap text-white text-base'}>{item.title}</motion.h6>
                                    </Link>
                                    <div className='w-full '>
                                      {item.dropDown && (<div className='w-full block ml-4'><motion.img src='/image/header/miniHook.svg' /></div>)}
                                    </div>
                                  </div>

                                  {
                                    item.dropDown &&
                                    <ul className='h-0 group-hover/innerDropDown:h-auto overflow-hidden transition-all duration-500 opacity-0 group-hover/innerDropDown:opacity-100 pl-[20%]'>
                                      {
                                        item.dropDown.map((item, index) => {
                                          return (
                                            <Link to={item.link}><li key={index} className='space-y-1'>
                                              <motion.h6 className='text-white text-sm' > {item.title}</motion.h6>
                                            </li>
                                            </Link>
                                          )
                                        })
                                      }
                                    </ul>
                                  }

                                </li>
                              )
                            })
                          }
                        </ul>
                      }
                    </li>
                  )
                })
              }
            </ul>
          </nav>
        }


      </motion.div>

    </header>
  )
}

export default Header