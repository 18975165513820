import React, { useState } from 'react'
import { motion } from "framer-motion"
import Banner from '../components/Banner'
import Lightbox from 'yet-another-react-lightbox'
import "yet-another-react-lightbox/styles.css";
import { Zoom } from 'yet-another-react-lightbox/plugins';



const Sertifikalarimiz = () => {

  const list = {
    visible: {
      opacity: 1,

      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
    hidden: { opacity: 0 },

  }





  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const images = [
    { id: 0, src: '/image/sertifikalar/1.jpg', title: 'Image 1' },
    { id: 1, src: '/image/sertifikalar/2.jpg', title: 'Image 2' },
    { id: 2, src: '/image/sertifikalar/3.jpg', title: 'Image 3' },
    { id: 3, src: '/image/sertifikalar/4.jpg', title: 'Image 4' },
    { id: 4, src: '/image/sertifikalar/5.jpg', title: 'Image 5' },
    { id: 5, src: '/image/sertifikalar/6.jpg', title: 'Image 6' },
  
  ];

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setIsOpen(true);
  };
  return (
    <div className='  '>
      <Banner />
      <motion.div initial="hidden" whileInView="visible" variants={list} className='  w-full bg-white  z-10 inner-container '>
        <h5 className='general-heading '> Sertifikalarımız</h5>

        <div className='grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 my-14 '>
          {

            
            images.map((item, index) => {
              return (
                <motion.div
                key={index}
                initial={{ opacity: 0, scale: 0.9 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ delay: index * 0.1, duration: 0.5 }}
              >
                <img   onClick={() => handleImageClick(item.id)}  key={item.id} src={item.src} className='hover:scale-105 transition-all duration-500 cursor-pointer' />
                </motion.div>
              )
            })
          }
        </div>





        {isOpen && (
        <Lightbox
          open={isOpen}
          close={() => setIsOpen(false)}
          slides={images.map(image => ({ src: image.src, alt: image.title }))}
          index={currentImageIndex}
          plugins={[Zoom]}
        />
      )}




      </motion.div>
    </div>
  )
}

export default Sertifikalarimiz