import React from 'react'
import Slider from '../components/Slider'
import HomeSection1 from '../components/HomeSection1'
import HomeSection3 from '../components/HomeSection3'
import HomeSection4 from '../components/HomeSection4'
import HomeSection5 from '../components/HomeSection5'
import HomeSection6 from '../components/HomeSection6'
import HomeSection7 from '../components/HomeSection7'


const Anasayfa = () => {
  return (
    <>
    <Slider />
    <HomeSection1 />
    {/* <HomeSection3 /> */}
    <HomeSection4 />
    {/* <HomeSection5 /> */}
    <HomeSection6 />
    {/* <HomeSection7 /> */}
    </>
  )
}

export default Anasayfa