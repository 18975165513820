import React from 'react'



import { DATA } from '../data/yurtici-data'

import Referanslar from '../components/Referanslar'

const Yurtici = () => {

 
  return (
    <Referanslar DATA={DATA}/>
  )


}

export default Yurtici