import React, { useEffect, useRef, useState } from 'react'
import { motion, useScroll } from "framer-motion"
import ParticlesMain from './ParticlesMain'
import { Link, useLocation } from 'react-router-dom'


const HomeSection1 = () => {
  const section1 = useRef()
  const location = useLocation()
  const [itemId, setItemId] = useState(0)

  const DATA = [
    {
      id: 0,
      title1: 'SIEMENS SIVACON',
      title2: 'Çözümleri',
      full_title: 'SIEMENS SIVACON Çözümleri',
      img: '/image/home-solutions/1.svg',
      link: '/cozumler/siemens-sivacon-cozumler'

    },
    {
      id: 1,
      title1: 'Proje',
      title2: 'Tasarım',
      full_title: 'Proje Tasarım',
      img: '/image/home-solutions/2.svg',
      link: '/cozumler/proje-tasarim'
    },
    {
      id: 2,
      title1: 'Saha Süpervizörlük',
      title2: 'Hizmetleri',
      full_title: 'Saha Supervisor hizmetleri',
      img: '/image/home-solutions/3.svg',
      link: '/cozumler/saha-supervizorluk-ve-devreye-alma-test'
    },
    // {
    //   id: 3,
    //   title1: 'Şarj',
    //   title2: 'Modelleri',
    //   full_title: 'Şarj Modelleri',
    //   img: 'image/home-solutions/4.svg'
    // },
  ]

  const list = {
    visible: {
      opacity: 1,

      transition: {
        when: "beforeChildren",
        staggerChildren: 0.2,
      },
    },
    hidden: { opacity: 0 },

  }

  const itemm = {
    visible: { opacity: [0, 1, 0.95, 1], scale: [1, 0.95, 1], translateX: 0, translateY: 0 },
    hidden: { opacity: 0, translateY: -100 },

  }

  const item1 = {
    visible: { opacity: [0, 1, 0.95, 1], scale: [1, 0.95, 1], translateY: 0 },
    hidden: { opacity: 0, translateY: -10 },

  }

  const itemText = { visible: { opacity: 1, transition: { duration: 1 } }, hidden: { opacity: 0 }, }


  // useEffect(() => {

  //   if (location.hash === '#section1') {
  //     window.scrollTo(0, section1.current.offsetTop)
  //   }


  // }, [location])



  return (
    <>

      <motion.section ref={section1} id='section1' initial="hidden" whileInView="visible" variants={list} className=" w-full bg-white py-14  overflow-hidden pt-24">
        <div className="    flex justify-center items-center flex-col ">
          <motion.h1 variants={itemText} className="text-heading-h3 text-black font-bold leading-10">ÇÖZÜMLER</motion.h1>
          <motion.p variants={itemText} className="font-medium  text-black">
          Güvenilir Çözümler, Kesintisiz Destek!
          </motion.p>
        </div>
        <div className="lg:w-1/2 w-full mx-auto mt-10">
          <motion.div className="grid grid-cols-3 lg:grid-cols-3 ">
            {
              DATA.map((item, index) => {
                return (
                  <Link to={item.link}>
                  <motion.div onClick={() => { setItemId(item.id) }} custom={item.id} key={item.id} className={`  flex flex-col space-y-7 ${(DATA.length - 1 === index) ? '' : 'border-r'}   border-black group hover:!scale-125 transition-all duration-500 cursor-pointer`}>
                    <span className='flex justify-center items-center  transition-all duration-500  '>
                      <motion.img variants={itemm} src={item.img} className="h-12 lg:h-16 text-red-700 " />
                    </span>
                    <div className={`text-center group-hover:text-tedakblue `}>
                      <motion.h5 variants={item1}>{item.title1}</motion.h5>
                      <motion.h5 variants={item1}>{item.title2}</motion.h5>
                    </div>
                  </motion.div>
                  </Link>
                )
              })
            }



          </motion.div>
        </div>


      </motion.section>




      <motion.section initial="hidden" whileInView="visible" variants={list} className="relative  ">
        <div className=" w-full h-full mx-auto  absolute top-0">
          <img src="image/home-solutions/banner.jpg" className="h-full w-full object-cover" />
        </div>

        <div className="container relative mx-auto  z-10  px-10 lg:px-48  overflow-hidden h-56">
          <ParticlesMain />
          {
            // DATA.map((item) => {
            //   console.log(itemId)
            //   if (itemId === item.id) {
            //     return (

            //       <div className="max-w-[600px] space-y-5 lg:space-y-5">

            //         <motion.h1 animate={{ opacity: 1, transition: { duration: 1 } }} variants={itemText} className="text-heading-h3 text-white font-bold leading-8">
            //           {item.full_title}
            //         </motion.h1>
            //         <motion.p animate={{ opacity: 1, transition: { duration: 1, delay: 0.2 } }} variants={itemText} className="text-white  font-medium mb-14 h-32">

            //         </motion.p>
            //         <Link to={item.link}>
            //           <div className='h-12 w-40 flex justify-center items-center relative cursor-pointer !mt-10'>
            //             <motion.div animate={{ width: '0%', opacity: 1, transition: { duration: 1, ease: 'easeIn' } }} variants={{ visible: { width: '0%', opacity: 1, transition: { duration: 1, ease: 'easeIn' } }, hidden: { width: '100%', opacity: 0 } }} className='absolute h-full w-full bg-tedakblue top-0 left-0'></motion.div>
            //             <motion.div animate={{ opacity: 1, transition: { ease: 'easeIn', duration: 1, delay: 1 } }} whileHover={{ scale: 1.1 }} variants={{ visible: { opacity: 1, transition: { ease: 'easeIn', duration: 1, delay: 2 } }, hidden: { opacity: 0 } }} className='h-full w-full bg-[#EFEFEF] flex justify-center items-center border'>
            //               <h6 >Daha Dazlası</h6>
            //             </motion.div>
            //           </div>
            //         </Link>


            //       </div>

            //     )
            //   }
            // })

          }

        </div>

      </motion.section>
    </>
  )
}

export default HomeSection1








