import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Banner from '../components/Banner';
import { useLocation } from 'react-router-dom';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import { Zoom } from 'yet-another-react-lightbox/plugins';

const VideoSection = ({ title, src, onClick }) => (

  <div className="h-64 space-y-5 relative" onClick={onClick}>
    <h5 className="text-lg border-l-4 border-tedakblue pl-4">{title}</h5>


    <div className='absolute h-full w-full  opacity-0'></div>
    <iframe
      width="100%"
      height="100%"
      src={src}
      title={title}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerPolicy="strict-origin-when-cross-origin"
      allowFullScreen
    ></iframe>
  </div>

);

const Medya = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [videoModal, setVideoModal] = useState({ isOpen: false, src: '', title: '' });
  const images = [
    { src: '/image/galeri/1.svg', title: 'Image 1' },
    { src: '/image/galeri/1.svg', title: 'Image 2' },
    { src: '/image/galeri/1.svg', title: 'Image 3' },
    { src: '/image/galeri/1.svg', title: 'Image 4' },
    { src: '/image/galeri/1.svg', title: 'Image 5' },
    { src: '/image/galeri/1.svg', title: 'Image 6' },
  ];

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setIsOpen(true);
  };

  const videos = [
    { title: "Fabrika Kabul Testi", src: "https://www.youtube.com/embed/6a_cO9APgdA?si=Ez3yvNC5NpNdJLvy" },
    { title: "System Pro E Power", src: "https://www.youtube.com/embed/Xks7DSagh0k?si=lHnAN91K2cX72g2o" },
    { title: "TEDAK EPLAN", src: "https://www.youtube.com/embed/uUinHop6Rkw?si=Cg415njI14aGtvY7" },
    { title: "Tedak Electric", src: "https://www.youtube.com/embed/-V5rnwWIu2Y?si=0erKzAua_nIdAVr0" },
  ];

  const openVideoModal = (src, title) => {
    setVideoModal({ isOpen: true, src, title });
  };

  const closeVideoModal = () => {
    setVideoModal({ isOpen: false, src: '', title: '' });
  };

  return (
    <div className="">
      <Banner />
      <div className="w-full bg-white z-10 inner-container">
        <h5 className="general-heading">Medya</h5>
        <div className="grid lg:grid-cols-2 my-14 gap-24">


          {videos.map((video, index) => (


            <VideoSection
              key={index}
              title={video.title}
              src={video.src}
              onClick={() => openVideoModal(video.src, video.title)}
            />
          ))}



        </div>
      </div>

      <motion.div
        id="musteri-yorumlari"
        className="w-full bg-white z-10 inner-container "
      >
        <h5 className="general-heading">Galeri</h5>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-24 mt-14">
          {images.map((item, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, scale: 0.9 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ delay: index * 0.1, duration: 0.5 }}
            >
              <motion.img
                onClick={() => handleImageClick(index)}
                src={item.src}
                className="hover:scale-105 transition-all duration-500 cursor-pointer aspect-square w-full"
              />
            </motion.div>
          ))}
        </div>
      </motion.div>

      {isOpen && (
        <Lightbox
          open={isOpen}
          close={() => setIsOpen(false)}
          slides={images.map((image) => ({ src: image.src, alt: image.title }))}
          index={currentImageIndex}
          plugins={[Zoom]}
        />
      )}

      <AnimatePresence>
        {videoModal.isOpen && (
          <motion.div

            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"

          >
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              transition={{ duration: 0.3 }}
              className="bg-white p-2 pt-4 shadow-lg w-11/12 md:w-2/3 lg:w-1/2">
              <div className="flex justify-between items-center">
                <h5 className="text-xl border-l-4 border-tedakblue pl-4 ">{videoModal.title}</h5>





                <div onClick={closeVideoModal} className='h-7 w-7  relative flex justify-center items-center cursor-pointer'>
                  <div className='absolute h-[2px] w-7 bg-black rotate-45'></div>
                  <div className='absolute h-[2px] w-7 bg-black -rotate-45'></div>
                </div>


              </div>
              <div className="aspect-video mt-4">
                <iframe
                  width="100%"
                  height="100%"
                  src={videoModal.src}
                  title={videoModal.title}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen


                ></iframe>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Medya;
