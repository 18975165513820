import React from 'react'
import { motion } from "framer-motion"
import Banner from '../components/Banner'

const Kurumsal = () => {
    // Define animation variants with staggerChildren for sequential animations
    const containerVariant = {
        hidden: { opacity: 1 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 1, // Time delay between animations of children
            },
        },
    }

    const fadeIn = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.8 } }
    }

    return (
        <div className='bg-white'>
            <Banner />

            <div className='inner-container'>
                <motion.div
                    className='w-full bg-white space-y-16'
                    variants={containerVariant}
                    initial="hidden"
                    whileInView="visible"
                >
                    {/* Kurumsal Section */}
                    <motion.div variants={fadeIn}>
                        <motion.h2 className='general-heading' variants={fadeIn}>Kurumsal</motion.h2>
                        <motion.h2 className='font-medium text-2xl text-gray-900' variants={fadeIn}>
                            Tecrübe ve Güvenle
                        </motion.h2>
                        <motion.h2 className='font-medium text-xl mb-4' variants={fadeIn}>
                            Teknolojik Elektrik Dağıtım Kabin Çözümleri.
                        </motion.h2>
                        <motion.p className='text-base text-p text-gray-500' variants={fadeIn}>
                            Tedak Teknolojik Elektrik Dağıtım Kabinleri olarak elektrik dağıtım, izleme ve kontrol kabinlerinin projelendirilmesi, montajı ve devreye alınması alanlarında uzmanlaşmış bir firmadır. 4000m² kapalı alan ve 1100m² açık alana sahip modern fabrikamızda, uzman ekibimizle Siemens Sivacon Teknoloji partnerliği çerçevesinde Sivacon S8, Sivacon S4 ve Alpha Tip testli panoların sistem entegratörlüğünü yapmaktayız.
                            <div className='h-3' />
                            Kurulduğumuz günden bu yana, sektördeki yenilikleri ve teknolojik gelişmeleri yakından takip ederek müşterilerimize en iyi çözümleri sunmaktayız. Profesyonel ekibimiz, projelendirme aşamasından montaj ve devreye almaya kadar tüm süreçlerde en yüksek kalite standartlarına göre hizmet vermektedir.
                            <div className='h-3' />
                            Tedak Teknolojik Elektrik Dağıtım Kabinleri olarak, kaliteli ürün ve hizmetlerimizle sektördeki yerimizi sağlamlaştırıyoruz. Yenilikçi ve sürdürülebilir çözümlerimizle, Türkiye ve global pazarda adımızı duyurmaya devam ediyoruz.
                        </motion.p>
                    </motion.div>

                    {/* Vizyonumuz Section */}
                    <motion.div variants={fadeIn}>
                        <motion.h2 className='general-heading' variants={fadeIn}>Vizyonumuz</motion.h2>
                        <motion.p className='text-p text-gray-500' variants={fadeIn}>
                            Uluslararası arenada Dünya markası olmak hedefimizdir.
                        </motion.p>
                    </motion.div>

                    {/* Misyonumuz Section */}
                    <motion.div id='Misyonumuz' variants={fadeIn}>
                        <motion.h2 className='general-heading' variants={fadeIn}>Misyonumuz</motion.h2>
                        <motion.p className='text-p text-gray-500' variants={fadeIn}>
                            Firmamız deneyimli uzman kadrosuna Ar-Ge ruhunu benimsetmiş, çağın koşullarına bağlı olarak oluşturduğu çözümler ile müşterilerine kesintisiz ve kaliteli hizmet sunmayı ilke edinmiştir.
                        </motion.p>
                    </motion.div>

                </motion.div>
            </div>
        </div>
    )
}

export default Kurumsal
