import React from 'react'



import { DATA } from '../data/yurtdisi-data'

import Referanslar from '../components/Referanslar'

const Yurtdisi = () => {

 
  return (
    <Referanslar DATA={DATA}/>
  )


}

export default Yurtdisi