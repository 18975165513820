import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Outlet, useLocation } from 'react-router-dom'
import { motion } from "framer-motion"


const HomeContainer = () => {
  const location = useLocation();

  const pageVariants = {
    initial: { translateY: -5,  opacity: 0 },
    in: { translateY: 0,opacity: 1 },
    out: { translateY:5,opacity: 0 }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'easeIn',
    duration: 0.6
  };



  return (
    <motion.div  

    >
        <Header />
        <motion.div
            key={location.pathname}
            initial='initial'
            animate='in'
            exit='out'
            variants={pageVariants}
            transition={pageTransition}
        >
        <Outlet/>
        </motion.div>
        <Footer />
    </motion.div>
  )
}

export default HomeContainer