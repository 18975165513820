import React, { useState } from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules'
import { motion, useScroll } from "framer-motion"
import { Link } from 'react-router-dom';

const Slider = () => {

  const options = {
    // your options here, for example:
    duration: 4000,
    smooth: true,
  };

  const [isAnimating, setIsAnimating] = useState(0);


  return (
    <section className="h-screen bg-blue-300 relative 3xl:container m-auto  zxczxc">
      <Swiper
        onSlideChange={(e) => setIsAnimating(e.activeIndex)}

        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,

        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation,]}
        className="h-screen bg-black "
      >
        <SwiperSlide className="h-screen relative bg-[#252541]">
          <div className="h-screen w-full relative z-40">
            <div className=" w-full mt-32 px-10  lg:px-48 leading-[1.1]">
              <motion.h1 animate={{ opacity: isAnimating === 0 ? [0, 1] : 0, transition: { duration: 2, ease: 'easeIn' } }} initial={{ opacity: 0 }} className="text-[50px] text-white font-bold ">SIVACON S8</motion.h1>
              <motion.h2 animate={{ opacity: isAnimating === 0 ? [0, 1] : 0, transition: { duration: 3, ease: 'easeIn' } }} initial={{ opacity: 0 }} className="text-[25px] text-white font-normal">Complete service from development the product  </motion.h2>
            </div>

          </div>
          <motion.img animate={{ scale: isAnimating === 0 ? [0.80, 1.1, 1.05] : 0.80, opacity: isAnimating === 0 ? [0.5, 0.7, 1] : 0.5, transition: { duration: 4, ease: 'easeIn' } }} src="image/slider/slider_tedak.png" className="w-full h-full absolute top-0 left-0 object-cover hidden md:block" />
          <motion.img animate={{ scale: isAnimating === 0 ? [0.80, 1.1, 1.05] : 0.80, opacity: isAnimating === 0 ? [0.5, 0.7, 1] : 0.5, transition: { duration: 4, ease: 'easeIn' } }} src="image/slider/mobill.png" className="w-full h-full absolute top-0 left-0 object-cover block md:hidden" />
        </SwiperSlide>


        <SwiperSlide className="h-screen relative bg-[#252541]">
          <div className="h-screen w-full relative z-40">
            <div className=" w-full mt-32 px-10  lg:px-48 leading-[1.1]">
              <motion.h1 animate={{ opacity: isAnimating === 1 ? [0, 1] : 0, transition: { duration: 2, ease: 'easeIn' } }} initial={{ opacity: 0 }} className="text-[50px] text-white font-bold ">SIVACON Tecnology Partner</motion.h1>
              <motion.h2 animate={{ opacity: isAnimating === 1 ? [0, 1] : 0, transition: { duration: 3, ease: 'easeIn' } }} initial={{ opacity: 0 }} className="text-[25px] text-white font-normal">Complete service from development the product  </motion.h2>
            </div>

          </div>
          <motion.img animate={{ scale: isAnimating === 1 ? [0.80, 1.1, 1.05] : 0.80, opacity: isAnimating === 0 ? [0.5, 0.7, 1] : 0.5, transition: { duration: 4, ease: 'easeIn' } }} src="image/slider/mobile.png" className="w-full h-full absolute top-0 left-0 object-cover" />
        </SwiperSlide>



        <SwiperSlide className="h-screen relative bg-[#252541]">
          <div className="h-screen w-full relative z-40">


          </div>
          <motion.img animate={{ scale: isAnimating === 2 ? [0.80, 1.1, 1.05] : 0.80,  transition: { duration: 4, ease: 'easeIn' } }} src="image/slider/slider3.png" className="w-full h-full absolute top-0 left-0 object-cover lg:object-contain" />
        </SwiperSlide>

        

                {/* <SwiperSlide className="h-screen relative bg-[#252541]">
          <div className="h-screen w-full relative z-40">
            <div className=" w-full mt-32 px-10  lg:px-48 leading-[1.1]">
              <motion.h1 animate={{ opacity: isAnimating === 2 ? [0, 1] : 0, transition: { duration: 2, ease: 'easeIn' } }} initial={{ opacity: 0 }} className="text-[50px] text-white font-bold ">SIVACON Tecnology Partner</motion.h1>
              <motion.h2 animate={{ opacity: isAnimating === 2 ? [0, 1] : 0, transition: { duration: 3, ease: 'easeIn' } }} initial={{ opacity: 0 }} className="text-[25px] text-white font-normal">Complete service from development the product  </motion.h2>
            </div>

          </div>
          <motion.img animate={{ scale: isAnimating === 2 ? [0.80, 1.1, 1.05] : 0.80, opacity: isAnimating === 0 ? [0.5, 0.7, 1] : 0.5, transition: { duration: 4, ease: 'easeIn' } }} src="image/slider/mobile.png" className="w-full h-full absolute top-0 left-0 object-cover" />
        </SwiperSlide> */}

      </Swiper>


      <Link to='#section1'>
        <div className=" absolute h-10 w-10  lg:bottom-28 lg:right-28 lg:right-0 bottom-40 left-[calc(50%-1.250rem)] z-50 flex justify-center items-center ">
          <img src="image/header/down.svg" className="zta cursor-pointer" />
        </div>
      </Link>



    </section>
  )
}

export default Slider