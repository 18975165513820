import { AnimatePresence, motion, useScroll } from "framer-motion";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { ScrollTrigger } from "gsap/ScrollTrigger";
// import required modules

import { useEffect, useLayoutEffect, useRef } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import Header from "./components/Header";
import Slider from "./components/Slider";
import Footer from "./components/Footer";
import HomeSection1 from "./components/HomeSection1";
import HomeSection2 from "./components/HomeSection2";
import HomeSection3 from "./components/HomeSection3";
import HomeSection4 from "./components/HomeSection4";
import HomeSection5 from "./components/HomeSection5";
import HomeSection6 from "./components/HomeSection6";
import HomeSection7 from "./components/HomeSection7";
import { Route, Routes, useLocation } from "react-router-dom";
import Anasayfa from "./pages/Anasayfa";
import Iletisim from "./pages/Iletisim";
import Urunler from "./pages/Urunler";
import Kurumsal from "./pages/Kurumsal";
import Cozumler from "./pages/Cozumler";
import Medya from "./pages/Medya";
import Referanslar from "./pages/Referanslar";
import Politikalarimiz from "./pages/Politikalarimiz";
import Sertifikalarimiz from "./pages/Sertifikalarimiz";
import Tarihce from "./pages/Tarihce";
import SivaconCozumler from "./pages/SiemensSivaconCozumler";
import SiemensSivaconCozumler from "./pages/SiemensSivaconCozumler";
import HomeContainer from "./container/HomeContainer";

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.defaults({
  toggleActions: "restart pause resume pause",
});

function App() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash === "#musteri-yorumlari") {
    } 
    if (location.hash === '#section1') {
      window.scrollTo(0, window.innerHeight)
    }

    
    else {
      window.scrollTo(0, 0);
    }
  }, [location]);

  const Container = useRef(null);

  useGSAP(
    () => {
      gsap.from(".a2", {
        yPercent: 200,
        ease: "none",
        scrollTrigger: {
          trigger: ".a1",
          start: "bottom bottom", // the default values
          end: "bottom bottom",
          scrub: 1,
        },
      });

      gsap.from(".zta", {
        yPercent: 200,
        ease: "none",
        scrollTrigger: {
          trigger: ".zxczxc",
          start: "bottom center",
          end: "bottom 100px",

          scrub: 1,
          // markers: true,
          // id: "scrub"
        },
      });
    },

    { scope: Container }
  );

  return (
    <div ref={Container} className="App relative 3xl:container mx-auto ">
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<HomeContainer />}>
            <Route path="/" element={<Anasayfa />} />
            <Route path="iletisim" element={<Iletisim />} />
            <Route path="urunler" element={<Urunler />} />
            <Route path="kurumsal" element={<Kurumsal />} />
            <Route path="tarihce" element={<Tarihce />} />

            <Route path="politikalarimiz" element={<Politikalarimiz />} />
            <Route path="sertifikalarimiz" element={<Sertifikalarimiz />} />

            <Route path="referanslar" element={<Referanslar />}>
              <Route path="yurtici" />
              <Route path="musteri-yorumlari" />
              <Route path="yurtdisi" />
            </Route>

            <Route path="cozumler/:id" element={<Cozumler />} />
            <Route
              path="cozumler/siemens-sivacon-cozumler"
              element={<SiemensSivaconCozumler />}
            />

            <Route path="medya" element={<Medya />} />
          </Route>
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export default App;
