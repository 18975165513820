import React from 'react'
import Banner from '../components/Banner'

const Tarihce = () => {
  return (
    <div className='  '>
      <Banner />
      <div className='h-96 w-full bg-white  z-10 inner-container '>
        <h5 className='general-heading '> Tarihçe</h5>



        <div class="  max-w-sm w-full mx-auto mt-14">
  <div class="animate-pulse flex space-x-4">
    <div className='bg-white h-20 w-full flex justify-center items-center'>
          <h5 className='text-3xl text-slate-200'>İçerik Güncelleniyor</h5>
    </div>

  </div>
</div>




      </div>
    </div>
  )
}

export default Tarihce