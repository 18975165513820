import React, { useState } from 'react'
import { motion } from "framer-motion"
import { useLocation, useParams } from 'react-router-dom'

import Banner from '../components/Banner'

const Referanslar = ({ DATA }) => {
  const [itemIndex, setItemIndex] = useState(0)

  const variants = {
    visible: index => ({
      opacity: 1,
      transition: {
        duration: (index + 1) * 0.1,
        delay: (index + 1) * 0.1,
      },
    }),
    hidden: { opacity: 0 },
  }

  // Function to sort array by name
  const sortByName = (array) => array.sort((a, b) => a.name.localeCompare(b.name));

  // Function to remove duplicates by name
  const removeDuplicatesByName = (array) => {
    const seen = new Set();
    return array.filter(item => {
      const duplicate = seen.has(item.name);
      seen.add(item.name);
      return !duplicate;
    });
  };

  // Create a combined content array for the first category (id: 0)
  const combinedContentArray = DATA.category
    .filter((e) => e.id !== 0)
    .flatMap((e) => e.contentArray);

  // Determine the content array based on the selected itemIndex
  const filteredData = itemIndex === 0
    ? [{ id: 0, type: 'Tümü', contentArray: removeDuplicatesByName(sortByName(combinedContentArray)) }]
    : DATA.category
        .filter((e) => e.id === itemIndex)
        .map(category => ({
          ...category,
          contentArray: sortByName(category.contentArray)
        }));

  console.log(filteredData)
  console.log(itemIndex)

  return (
    <div className='bg-white'>
      <Banner />
      <div className='flex flex-col w-full min-h-[100vh] inner-container'>
        <div className='flex flex-col lg:flex-row'>
          <h5 className='general-heading mb-2'>{`${DATA.title}`}</h5>
          <h5 className='general-heading mb-2 px-1 hidden lg:block'>-</h5>
          <h5 className='general-heading'>Referanslar</h5>
        </div>

        <div className='md:w-2/3 lg:w-full w-full my-14'>
          <div className='grid grid-cols-5 w-full h-10 relative mb-10'>
            {
              DATA.category.map((item, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => setItemIndex(index)}
                    className={`${itemIndex === index ? 'lg:text-lg font-medium' : 'lg:text-base font-normal'} flex items-center justify-center cursor-pointer text-xs transition-all duration-500`}
                  >
                    {item.type}
                  </div>
                )
              })
            }
            <div style={{ left: itemIndex * 20 + '%' }} className={`absolute h-full w-1/5 bg-slate-200 opacity-35 transition-all duration-500 border-b border-tedakblue`}></div>
          </div>

          <div className='w-full z-10 grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 mb-10'>
            {
              filteredData[0].contentArray.map((item, index) => {
                console.log(item)
                return (
                  <motion.div key={index} custom={index} animate="visible" initial="hidden" variants={variants} className='hover:scale-105 transition-all duration-500'>
                    <img src={item.image} alt="" className='' />
                  </motion.div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Referanslar
