import React from 'react'
import Yurtici from './Yurtici'
import Yurtdisi from './Yurtdisi'
import { useLocation, useParams } from 'react-router-dom'
import MusteriYorumlari from './MusteriYorumlari'

const Referanslar = () => {
  const location = useLocation()

  const splitedLocation = location.pathname.split('/')
  const path1 = splitedLocation[2]
  const path2 = splitedLocation[3]
  console.log(path1)
  return (
    <>
    {
      path1==='yurtici'&&(
        <Yurtici/>
  )
    }

{
      path1==='yurtdisi'&&(
        <Yurtdisi/>
  )
    }
    {
      path1==='musteri-yorumlari'&&(
        <MusteriYorumlari/>
  )
    }
</>
  )
}

export default Referanslar