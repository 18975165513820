export const DATA =
{
    title:'Yurt İçi',
    category: [
        {
            id:0,
            type: 'Tümü',


        },
        {
            id:1,
            type: 'Endüstri',
            contentArray: [
                {image: '/image/references/yurtici/endustri/endustri-02.png', name: 'polisan'},
                {image: '/image/references/yurtici/endustri/endustri-03.png', name: 'poliport'},
                {image: '/image/references/yurtici/endustri/endustri-04.png', name: 'akkim'},
                {image: '/image/references/yurtici/endustri/endustri-05.png', name: 'dogus'},
                {image: '/image/references/yurtici/endustri/endustri-06.png', name: 'kord-sa'},
                {image: '/image/references/yurtici/endustri/endustri-10.png', name: 'starwood'},
                {image: '/image/references/yurtici/endustri/endustri-11.png', name: 'kastamonu'},
                {image: '/image/references/yurtici/endustri/endustri-12.png', name: 'yildizentegre'},
                {image: '/image/references/yurtici/endustri/endustri-13.png', name: 'dunapacdentas'},
                {image: '/image/references/yurtici/endustri/endustri-14.png', name: 'beyazkagit'},
                {image: '/image/references/yurtici/endustri/endustri-15.png', name: 'karaholding'},
                {image: '/image/references/yurtici/endustri/endustri-16.png', name: 'assanaluminyum'},
                {image: '/image/references/yurtici/endustri/endustri-17.png', name: 'kaanlar'},
                {image: '/image/references/yurtici/endustri/endustri-18.png', name: 'aksa'},
                {image: '/image/references/yurtici/endustri/endustri-19.png', name: 'isdemir'},
                {image: '/image/references/yurtici/endustri/endustri-20.png', name: 'kardemir'},
                {image: '/image/references/yurtici/endustri/endustri-21.png', name: 'kivanc'},
                {image: '/image/references/yurtici/endustri/endustri-22.png', name: 'köksan'},
                {image: '/image/references/yurtici/endustri/endustri-23.png', name: 'tosyali-toyo'},
                {image: '/image/references/yurtici/endustri/endustri-24.png', name: 'assanhanil'},
                {image: '/image/references/yurtici/endustri/endustri-25.png', name: 'tatmetal'},
                {image: '/image/references/yurtici/endustri/endustri-26.png', name: 'asilcelik'},
                {image: '/image/references/yurtici/endustri/endustri-27.png', name: 'tekno'},
                {image: '/image/references/yurtici/endustri/endustri-29.png', name: 'petlas'},
                {image: '/image/references/yurtici/endustri/endustri-30.png', name: 'ensar'},
                {image: '/image/references/yurtici/endustri/endustri-31.png', name: 'hayat'},
                {image: '/image/references/yurtici/endustri/endustri-35.png', name: 'alkim'},
                {image: '/image/references/yurtici/endustri/endustri-36.png', name: 'aynes'},
                {image: '/image/references/yurtici/endustri/endustri-37.png', name: 'sek'},
                {image: '/image/references/yurtici/endustri/endustri-38.png', name: 'etimadenisletmeleri'},
                {image: '/image/references/yurtici/endustri/endustri-39.png', name: 'enpay'},
                {image: '/image/references/yurtici/endustri/endustri-40.png', name: 'menderes'},
                {image: '/image/references/yurtici/endustri/endustri-41.png', name: 'toren'},
                {image: '/image/references/yurtici/endustri/endustri-42.png', name: 'guralseramik'},
                {image: '/image/references/yurtici/endustri/endustri-45.png', name: 'polygurteks'},
                {image: '/image/references/yurtici/endustri/endustri-46.png', name: 'micha'},
                {image: '/image/references/yurtici/endustri/endustri-48.png', name: 'erbakir'},
                {image: '/image/references/yurtici/endustri/endustri-49.png', name: 'AGT'},
                {image: '/image/references/yurtici/endustri/endustri-50.png', name: 'ABB'},
                {image: '/image/references/yurtici/endustri/endustri-51.png', name: 'karaca'},
                {image: '/image/references/yurtici/endustri/endustri-52.png', name: 'ferrero'},
                {image: '/image/references/yurtici/endustri/endustri-53.png', name: 'Cargill'},
                {image: '/image/references/yurtici/endustri/endustri-33.png', name: 'KONFRUT'},
                {image: '/image/references/yurtici/endustri/endustri-44.png', name: 'dohler'},
                {image: '/image/references/yurtici/endustri/endustri-54.png', name: 'igsas'},
                {image: '/image/references/yurtici/endustri/endustri-55.png', name: 'anatolia'},
       
             
        
  

                // anatolia
                
                




            ]

        },
        {
            id:2,
            type: 'Enerji',
            contentArray: [
                {image: '/image/references/yurtici/enerji/enerji-01.png', name: 'zorlu'},
                {image: '/image/references/yurtici/enerji/enerji-02.png', name: 'soyak'},
                {image: '/image/references/yurtici/enerji/enerji-03.png', name: 'izdemirenerji'},
                // {image: '/image/references/yurtici/enerji/enerji-04.png', name: 'kesir'},
                {image: '/image/references/yurtici/enerji/enerji-05.png', name: 'maren'},
                {image: '/image/references/yurtici/enerji/enerji-06.png', name: 'teias'},
                {image: '/image/references/yurtici/enerji/enerji-07.png', name: 'fabay'},
                {image: '/image/references/yurtici/enerji/enerji-08.png', name: 'akcaenerji'},
                {image: '/image/references/yurtici/enerji/enerji-09.png', name: 'emirlerenerji'},
                {image: '/image/references/yurtici/enerji/enerji-10.png', name: 'turkerler'},
                {image: '/image/references/yurtici/enerji/enerji-11.png', name: 'mbholding'},
                {image: '/image/references/yurtici/enerji/enerji-12.png', name: 'ogulenerji'},
                {image: '/image/references/yurtici/enerji/enerji-13.png', name: 'sonmezholding'},
                // {image: '/image/references/yurtici/enerji/enerji-14.png', name: 'iltekno'},
                {image: '/image/references/yurtici/enerji/enerji-16.png', name: 'greeneco'},
                {image: '/image/references/yurtici/enerji/enerji-17.png', name: 'yilsan'},
                {image: '/image/references/yurtici/enerji/enerji-18.png', name: 'salbakos'},
                {image: '/image/references/yurtici/enerji/enerji-19.png', name: 'mege'},
                {image: '/image/references/yurtici/enerji/enerji-20.png', name: 'akkuyunukleer'},
                {image: '/image/references/yurtici/enerji/enerji-21.png', name: 'guneybiyogaz'},
                {image: '/image/references/yurtici/enerji/enerji-22.png', name: 'sevval'},
                {image: '/image/references/yurtici/enerji/enerji-23.png', name: 'AGT'},


            ]

        },
        {
            id:3,
            type: 'Petrokimya',
            contentArray: [
                {image: '/image/references/yurtici/petrokimya/oil-01.png', name: 'tupras'},
                {image: '/image/references/yurtici/petrokimya/oil-03.png', name: 'turkiyepetrolleri'},
                {image: '/image/references/yurtici/petrokimya/oil-06.png', name: 'petkim'},
                {image: '/image/references/yurtici/petrokimya/oil-12.png', name: 'starrafineri'},
                {image: '/image/references/yurtici/petrokimya/oil-10.png', name: 'amecfosterwheeler'},

            ]

        },

        {
            id:4,
            type: 'Bina',
            contentArray: [
                {image: '/image/references/yurtici/bina/bina-01.png', name: 'themarmara'},
                {image: '/image/references/yurtici/bina/bina-02.png', name: 'limakeurasia'},
                {image: '/image/references/yurtici/bina/bina-03.png', name: 'radisson'},
                {image: '/image/references/yurtici/bina/bina-04.png', name: 'tubitak'},
                {image: '/image/references/yurtici/bina/bina-05.png', name: 'doubletree'},
                {image: '/image/references/yurtici/bina/bina-07.png', name: 'rixos-premium-tekirova'},
                {image: '/image/references/yurtici/bina/bina-08.png', name: 'rixos-premium-gocek'},
                {image: '/image/references/yurtici/bina/bina-09.png', name: 'atlantis'},
                {image: '/image/references/yurtici/bina/bina-12.png', name: 'askahotels'},
                {image: '/image/references/yurtici/bina/bina-13.png', name: 'tcistanbulkulturuniversitesi'},
                {image: '/image/references/yurtici/bina/bina-14.png', name: 'regnumcaryagolf'},
                {image: '/image/references/yurtici/bina/bina-15.png', name: 'opsmall'},
                {image: '/image/references/yurtici/bina/bina-16.png', name: 'elsan'},
                {image: '/image/references/yurtici/bina/bina-17.png', name: 'sinpasgyo'},
                {image: '/image/references/yurtici/bina/bina-18.png', name: 'sunplaza'},
                {image: '/image/references/yurtici/bina/bina-19.png', name: 'lemankultur'},
                {image: '/image/references/yurtici/bina/bina-20.png', name: 'iyaspark'},
                {image: '/image/references/yurtici/bina/bina-21.png', name: 'podium'},
                {image: '/image/references/yurtici/bina/bina-22.png', name: 'afad'},
                {image: '/image/references/yurtici/bina/bina-23.png', name: 'tapuvekadastro'},
                {image: '/image/references/yurtici/bina/bina-24.png', name: 'AGT'},
                {image: '/image/references/yurtici/bina/bina-10.png', name: 'BRITISHAMERICANTOBACCO'},

            ]

        },

    ]




}

