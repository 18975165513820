import React, { useRef } from 'react'
import gsap from 'gsap';
import { useGSAP } from "@gsap/react";
import { motion, useScroll } from "framer-motion"
import { Link } from 'react-router-dom';

const HomeSection6 = () => {
  const sectionContainer = useRef()


  const DATA = [
    {
      title: 'Lorem Ipsum',
      content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat',
      dataClass: 'referencesItem1'
    },
    {
      title: 'Lorem Ipsum',
      content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat',
      dataClass: 'referencesItem2'
    },
    {
      title: 'Lorem Ipsum',
      content: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat',
      dataClass: 'referencesItem3'
    },
  ]

  useGSAP(
    () => {



      gsap.from(".referencesItem1", {
        yPercent: 40,
        ease: "none",
        scrollTrigger: {
          trigger: ".referencesSection",
          start: "top center", // the default values
          end: "bottom bottom",
          scrub: 1,
          // markers: true,
          // id: "scrub"
        },
      });


      gsap.from(".referencesItem2", {
        yPercent: 80,
        ease: "none",
        scrollTrigger: {
          trigger: ".referencesSection",
          start: "top center", // the default values
          end: "bottom bottom",
          scrub: 1
        },
      });


      gsap.from(".referencesItem3", {
        yPercent: 120,
        ease: "none",
        scrollTrigger: {
          trigger: ".referencesSection",
          start: "top center", // the default values
          end: "bottom bottom",
          scrub: 1
        },
      });

    },

    { scope: sectionContainer }
  );



  const list = {
    visible: {
      opacity: 1,

      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
    hidden: { opacity: 0 },

  }

  const itemText = { visible: { opacity: 1, transition: { duration: 1 } }, hidden: { opacity: 0 }, }
  const itemImage = { visible: { opacity: 1, scale: 1.5, transition: { duration: 2 } }, hidden: { opacity: 0.5, scale: 1 }, }
  const itemHook = { visible: { translateX:0,opacity: 1, transition: { duration: 1 } }, hidden: { opacity: 0 , translateX:10}, }

  const itemImage2 = { visible: { translateX:0,opacity: 1, transition: { duration: 1 } }, hidden: { opacity: 0 , translateX:-10}, }

  const itemLine = {visible:{width:'100%',transition:{duration:1}},hidden:{width:'0%'}}


  return (
    <section  ref={sectionContainer} className="   relative  overflow-hidden">
      <div className="absolute  mx-auto w-full lg:-top-28 top-0">
        <img src="image/1.jpg" className="w-full mx-auto" />
      </div>

      <div>
        {/* <motion.div initial="hidden" whileInView="visible" variants={list} className="container mx-auto  relative  referencesSection ">
          <div className=" text-white font-bold mt-10 lg:m-28 flex justify-center">
            <h2 className="text-heading-h2">Projeler</h2>
          </div>

          <div  className="grid grid-cols-1 lg:grid-cols-3 gap-10 px-10 lg:mx-28">


            {
              DATA.map((item, index) => {
                return(
                <div key={index} className={`flex flex-col !shadow-2xl bg-black ${item.dataClass}`}>
                  <motion.div className='flex flex-col'>
                  <div className=" w-full overflow-hidden">
                    <motion.img variants={itemImage} src="image/references/1.jpg" className='w-full'/>
                  </div>
                  <div className=" w-full bg-black p-10 py-5">
                    <motion.h1  variants={itemText} className="text-[35px] text-white font-bold leading-[1] mb-5">Lorem Ipsum</motion.h1>
                    <motion.p variants={itemText} className="text-white mb-7">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat</motion.p>
                    <motion.img variants={itemHook} src="image/references/hook.svg" />
                  </div>
                  </motion.div>
                </div>

)

              })
            }


          </div>
        </motion.div> */}



        {/* <motion.div initial="hidden" whileInView="visible" variants={list} className="container mx-auto  relative  px-10 lg:px-20">
          <div className=" text-white font-bold  lg:m-28 flex justify-center flex-col items-center mb-10">
            <motion.h2 variants={itemText} className="text-heading-h2 mb-5 mt-10">Bizim sözümüz</motion.h2>
            <motion.p variants={itemText} className="lg:max-w-2xl lg:text-center">Daha büyük üretim tesislerimiz, daha büyük ve güçlü makine parkımızla daha esnek, aynı zamanda ihtiyaç ve isteklerinizi gerçekleştirebilmeniz için size daha fazla kapasite sunuyoruz.</motion.p>
          </div>




          <motion.div className="grid grid-cols-1 lg:grid-cols-5 lg:mx-28">

            <div className="  flex flex-col items-center space-y-7">
              <div className="h-40 w-40 rounded-full ">
                <motion.img  variants={itemText}  src="image/promise/1.png" className="h-full w-full" />
              </div>
              <motion.h6 variants={itemText} className="text-white font-bold text-[26px] leading-[1.3] text-start w-full">Teknoloji ortağı</motion.h6>
              <motion.p variants={itemText} className="text-white">En son teknolojiye sahip akıllı fabrika – Endüstri 4.0</motion.p>
            </div>

            <div className="lg:h-72 h-20 flex items-center my-10 lg:my-0">
              <div className="w-full h-full lg:h-20 lg:w-48 flex    justify-center items-center lg:block">
                  <motion.div variants={itemLine} className='lg:h-px lg:!w-full !w-px h-full bg-white'></motion.div>
              </div>
            </div>

            <div className="  flex flex-col items-center space-y-7">
              <div className="h-40 w-40 rounded-full ">
                <motion.img variants={itemText} src="image/promise/1.png" className="h-full w-full" />
              </div>
              <motion.h6 variants={itemText} className="text-white font-bold text-[26px] text-start w-full">Teknoloji ortağı</motion.h6>
              <motion.p variants={itemText} className="text-white">En son teknolojiye sahip akıllı fabrika – Endüstri 4.0</motion.p>
            </div>

            <div className="lg:h-72 h-20 flex items-center  my-10 lg:my-0">
              <div className="w-full h-full lg:h-20 lg:w-48 flex    justify-center items-center lg:block">
                  <motion.div variants={itemLine} className='lg:h-px lg:!w-full !w-px h-full bg-white'></motion.div>
              </div>
            </div>

            <div className="  flex flex-col items-center space-y-7">
              <div className="h-40 w-40 rounded-full ">
                <motion.img variants={itemText} src="image/promise/1.png" className="h-full w-full" />
              </div>
              <motion.h6 variants={itemText} className="text-white font-bold text-[26px] text-start w-full">Teknoloji ortağı</motion.h6>
              <motion.p variants={itemText} className="text-white">En son teknolojiye sahip akıllı fabrika – Endüstri 4.0</motion.p>
            </div>




          </motion.div>

        </motion.div> */}




        <motion.div  initial="hidden" whileInView="visible" variants={list} className="container mx-auto  relative my-36 px-10 lg:px-20">
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:mx-28 gap-16 ">
            <div className="">
              <motion.img  variants={itemImage2} src="image/institutional/2.jpg" />
            </div>


            <div className="flex flex-col items-start">
              <motion.img variants={itemHook} src="image/institutional/1.svg" className="h-10" />
              <motion.h1 variants={itemHook} className="text-[35px] text-white font-bold">Kurumsal</motion.h1>
              <div className="space-y-7">
                <motion.p variants={itemHook} className="text-white">Tedak Teknolojik Elektrik Dağıtım Kabinleri olarak elektrik dağıtım, izleme ve kontrol kabinlerinin projelendirilmesi, montajı ve devreye alınması alanlarında uzmanlaşmış bir firmadır. 4000m² kapalı alan ve 1100m² açık alana sahip modern fabrikamızda, 17 kişilik beyaz yakalı, 29 kişilik mavi yakalı ekibimizle Siemens Sivacon Teknoloji partnerliği çerçevesinde Sivacon S8, Sivacon S4 ve Alpha Tip testli panoların sistem entegratörlüğünü yapmaktayız.</motion.p>
               
                <Link to={'/kurumsal'} className='h-14  b-red-700 flex justify-center items-center relative cursor-pointer w-40'>
                    <motion.div variants={{ visible: { width: '0%', opacity: 1, transition: { duration: 1, ease: 'easeIn' } }, hidden: { width: '100%', opacity: 0 } }} className='absolute h-full w-full bg-tedakblue top-0 left-0'></motion.div>
                    <motion.div variants={{ visible: { opacity: 1, transition: { ease: 'easeIn', duration: 1, delay: 2 } }, hidden: { opacity: 0 } }} className='px-4 lg:px-8 h-full w-full bg-[#EFEFEF] flex justify-center items-center border'>
                      <h6 className=''>Daha Dazlası</h6>
                    </motion.div>
                  </Link>
              </div>
            </div>

          </div>



        </motion.div>
      </div>

    </section>
  )
}

export default HomeSection6