export const DATA =
{
    title:'Yurt Dışı',
    category: [
        {
            id:0,
            type: 'Tümü',


        },

        {
            id:1,
            type: 'Endüstri',
            contentArray: [
                // {image: '/image/references/yurtdisi/endustri/endustri-01.png', name: 'ABB'},
                // {image: '/image/references/yurtdisi/endustri/endustri-07.png', name: 'Cargill'},
                {image: '/image/references/yurtdisi/endustri/endustri-08.png', name: 'Asos'},
                {image: '/image/references/yurtdisi/endustri/endustri-09.png', name: 'AGT'},
                // {image: '/image/references/yurtdisi/endustri/endustri-33.png', name: 'KONFRUT'},
                // {image: '/image/references/yurtdisi/endustri/endustri-43.png', name: 'FERRERO'},
                {image: '/image/references/yurtdisi/endustri/endustri-11.png', name: 'kastamonu'},
                {image: '/image/references/yurtdisi/endustri/endustri-31.png', name: 'hayat'},
                {image: '/image/references/yurtdisi/endustri/endustri-12.png', name: 'yildizentegre'},
                // {image: '/image/references/yurtdisi/endustri/endustri-44.png', name: 'dohler'},

            ]

        },
        {
            id:2,
            type: 'Enerji',
            contentArray: [
                {image: '/image/references/yurtdisi/enerji/enerji-15.png', name: 'Mazoonelectricity'},
                {image: '/image/references/yurtdisi/enerji/enerji-04.png', name: 'kesir'},
                {image: '/image/references/yurtdisi/enerji/enerji-14.png', name: 'iltekno'},
                {image: '/image/references/yurtdisi/enerji/enerji-01.png', name: 'ABB'},
            ]

        },

        {
            id:3,
            type: 'Petrokimya',
            contentArray: [
                {image: '/image/references/yurtdisi/petrokimya/oil-02.png', name: 'SOCAR'},
                {image: '/image/references/yurtdisi/petrokimya/oil-04.png', name: 'BP'},
                {image: '/image/references/yurtdisi/petrokimya/oil-05.png', name: 'BlackSea'},
                {image: '/image/references/yurtdisi/petrokimya/oil-07.png', name: 'TCO'},
                {image: '/image/references/yurtdisi/petrokimya/oil-08.png', name: 'SCP'},
                {image: '/image/references/yurtdisi/petrokimya/oil-09.png', name: 'QATARGAS'},
                // {image: '/image/references/yurtdisi/petrokimya/oil-10.png', name: 'amecfosterwheeler'},
                {image: '/image/references/yurtdisi/petrokimya/oil-11.png', name: 'UNG'},
                {image: '/image/references/yurtdisi/petrokimya/oil-01.png', name: 'ABB'},
            ]

        },

        {
            id:4,
            type: 'Bina',
            contentArray: [
                // {image: '/image/references/yurtdisi/bina/bina-06.png', name: 'AGT'},
                // {image: '/image/references/yurtdisi/bina/bina-10.png', name: 'BRITISHAMERICANTOBACCO'},
                {image: '/image/references/yurtdisi/bina/bina-11.png', name: 'tosyalialgerie'},
            ]

        },

    ]




}

