import React from 'react'
import { motion } from "framer-motion"
import Banner from '../components/Banner'


const DATA = [
  {
    title: 'SIVACON S8 Plus',
    image: '/image/certificates/2.jpg',
    url: 'https://tedaktr.sharepoint.com/:b:/g/ESm_R2IQP1lAgXgaVKZ5qB0BWyiIsb11GCf7WSrXzjU0HQ?e=SfLz8M'
  },
  {
    title: 'SIVACON S8 Plus-İPDF',
    image: '/image/certificates/2.jpg',
    url: 'https://tedaktr.sharepoint.com/:b:/g/ET3KtZS1EA5Hj8VQN8hagzMBuLzCcw4mRUW5NEEAc2kqbg?e=1dWBQB'
  },
  {
    title: 'SIVACON S8 Planning Manual',
    image: '/image/certificates/3.jpg',
    url: 'https://tedaktr.sharepoint.com/:b:/g/EWctIjwf_YxDmvJ0IqWQKp0BR3xxovpMH29bQ78MSPSSvg?e=srp4fv'
  },
  {
    title: 'Intelligent Power Distrubution',
    image: '/image/certificates/1.jpg',
    url: 'https://tedaktr.sharepoint.com/:b:/g/EYhlAljBjOBEme1XPfXxL78B7wS56iZd0jb-SrUv08iIWA?e=2sgABu'
  },

  {
    title: 'LV Power Distribution and Electrical Installation Technology',
    image: '/image/certificates/4.jpg',
    url: 'https://tedaktr.sharepoint.com/:b:/g/ESzVBgTgHJRKnm35aUSAqb0BQDZqXeAZw77ftPCiVmRMNg?e=oalTHM'
  },

]
const SiemensSivaconCozumler = () => {
  const list = {
    visible: {
        opacity: 1,

        transition: {
            when: "beforeChildren",
            staggerChildren: 0.1,
        },
    },
    hidden: { opacity: 0 },

}

const itemText = { visible: { opacity: 1, transition: { duration: 1 } }, hidden: { opacity: 0 }, }
const itemScale = { visible: { opacity: 1,scale:1, transition: { duration: 1 } }, hidden: { opacity: 0 ,scale:1.2}, }
  return (
    <div className='  '>
      <Banner />
      <motion.div initial="hidden" whileInView="visible" variants={list}  className=' w-full bg-white  z-10 inner-container '>
        <h5 className='general-heading '> SIEMENS SIVACON Çözümleri</h5>

        <div className='grid  md:grid-cols-2 xl:grid-cols-3 my-14 lg:gap-24 gap-10'>
          {
            DATA.map((item, index) => {
              return (
                <a target='_blank' href={item.url} className='min-h-28 '>
                  <div className=' w-full flex flex-col   space-y-4  group hover:scale-105 transition-all duration-500'>
                  <motion.div variants={itemText} className='flex flex-col justify-between '>
                      <h5 className='font-medium  text-lg border-l-4 border-tedakblue pl-4 group-hover:text-tedakblue transition-all duration-500'>{item.title}</h5>

                    </motion.div>
                    <div variants={itemText} className='   w-full overflow-hidden'>
                      <div className='group-hover:scale-105 h-full w-full transition-transform duration-500'>
                      <motion.img variants={itemScale}  src={item.image} className=' w-full  ' />
                      </div>
                    </div>

                    <motion.div variants={itemText} className='flex justify-end '>
                      <h5 className='border-b group-hover:text-tedakblue transition-all duration-500'>İncele</h5>
                    </motion.div>

                  </div>
                </a>
              )
            })
          }



        </div>




      </motion.div>
    </div>
  )
}

export default SiemensSivaconCozumler