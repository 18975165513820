import React from 'react'
import { motion } from "framer-motion"
import Banner from '../components/Banner'
const Politikalarimiz = () => {
  return (
    <div className='  '>
            <Banner/>
    <div className='h-96 w-full bg-white  z-10 inner-container '>
        <h5 className='general-heading '> Politikalarımız</h5>




    </div>
</div>

  )
}

export default Politikalarimiz