import React from 'react'
import { motion, useScroll } from "framer-motion"
import { Element } from 'react-scroll'
const HomeSection5 = () => {


  const list = {
    visible: {


      transition: {
        when: "beforeChildren",
        staggerChildren: 0.2,
      },
    },


  }

  const itemText = { visible: { opacity: 1, transition: { duration: 1 } }, hidden: { opacity: 0 }, }
  const itemImage = { visible: {opacity:1,scale:1.5,transition: { duration: 2 } }, hidden: {opacity:0.5,scale:1}, }


  return (

    <motion.section  initial="hidden" whileInView="visible" variants={list} className="relative  bg-white">

    <div className="  pt-14  px-10 lg:px-48 relative  mb-7">
      <motion.h1 variants={itemText} className="text-[40px] leading-[1] text-black font-bold">
          Sophisticated technological installations
    
        </motion.h1>

    </div>


    <div className="grid grid-cols-3 grid-rows-1 gap-10">
      <div className="col-span-2 overflow-hidden">
        <motion.img variants={itemImage} src="image/home-projects/33.jpg" className="object-cover h-full" />
      </div>
      <div className="col-start-3 overflow-hidden">
        <motion.img variants={itemText}  src="image/home-projects/44.jpg" className="object-cover h-full" />
      </div>
    </div>



    <div className="  py-14  px-10 lg:px-48 relative  ">
      <motion.p variants={itemText}  className="  font-medium mb-7 text-black">

      Modern technology, the latest technological procedures and suitable software provide us with a starting point for excellence in every project. At the same time, we are also providing educated and experienced staff, which is increasingly enriching the Elpro Križnič team.
      </motion.p>

      <motion.p variants={itemText}  className="  font-medium  text-black">
      
      We are aware that for the most demanding technological installations we need a synergy of both, as this is the only way we can achieve impressive quality standards.
      </motion.p>
    </div>






  </motion.section>
  )
}

export default HomeSection5