import React from 'react'
import { motion } from "framer-motion"
const Urunler = () => {

    const DATA = [
        {
            image: 'image/products/1.jpg',
        },
        {
            image: 'image/products/2.jpg',
        },
        {
            image: 'image/products/1.jpg',
        },
        {
            image: 'image/products/2.jpg',
        },
    ]


    const list = {
        visible: {
            opacity: 1,

            transition: {
                when: "beforeChildren",
                staggerChildren: 0.1,
            },
        },
        hidden: { opacity: 0 },

    }

    const itemText = { visible: { opacity: 1, transition: { duration: 1 } }, hidden: { opacity: 0 }, }
    const itemLine = { visible: { width: '100%', transition: { duration: 1 } }, hidden: { width: '0%' } }
    return (
        <motion.div initial="hidden" whileInView="visible" variants={list} className='bg-white'>
            <motion.img   className='w-full h-72 object-cover ' src='/image/banner/1.jpg' />


            <div className='w-full lg:px-48 px-10 '>
                <div className="    flex justify-center items-center flex-col pt-16 ">
                    <motion.h2 variants={itemText} className="text-heading-h2 text-black font-normal">ÜRÜNLER</motion.h2>
                    <motion.p variants={itemText}  className="text-black max-w-xl text-center">
                    Sektördeki gelişmeleri yakından takip ederek daha teknolojik üretimler yapabilmeyi ve bu ürünleri en uygun şartlarda pazarlamayı sürekli olarak geliştirmekteyiz.
                    </motion.p>
                </div>
                <div className='w-full  bg-white py-16 grid grid-cols-1 lg:grid-cols-3  gap-16'>

                    {
                        DATA.map((item, index) => {
                            return (
                                <div key={index} className=' h-full'>
                                    <motion.img variants={itemText} src={item.image} />
                                    <div className="flex flex-col">

                                        <div className=" ">
                                            <motion.p variants={itemText} className="  font-medium  pl-3 mb-3 text-black">Siemens Sivacon Pano</motion.p>
                                            <motion.div variants={itemLine} className="h-px w-full bg-black mb-6"></motion.div>
                                            <div className="flex mb-12">
                                                <motion.img variants={itemText} src="image/home-product/i1.jpg" />
                                                <motion.img variants={itemText} src="image/home-product/i2.jpg" />
                                            </div>


                                            <div className='h-12 w-40 b-red-700 flex justify-center items-center relative cursor-pointer'>
                                                <motion.div variants={{ visible: { width: '0%', opacity: 1, transition: { duration: 1, ease: 'easeIn' } }, hidden: { width: '100%', opacity: 0 } }} className='absolute h-full w-full bg-tedakblue top-0 left-0'></motion.div>
                                                <motion.div whileHover={{ scale: 1.1 }} variants={{ visible: { opacity: 1, transition: { ease: 'easeIn', duration: 1, delay: 2 } }, hidden: { opacity: 0 } }} className='h-full w-full bg-[#EFEFEF] flex justify-center items-center border'>
                                                    <h6 className='text-tedakblue'>Daha Fazlası</h6>
                                                </motion.div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }


                </div>
            </div>
        </motion.div>
    )
}

export default Urunler