import React, { useEffect, useState } from 'react'
import { motion } from "framer-motion"
import { useLocation, useParams } from 'react-router-dom'

const DATA = [
    {
        id: 'sivacon-cozumler',
        name: 'SIEMENS SIVACON Çözümleri'
    },
    {
        id: 'proje-tasarim',
        name: 'Proje Tasarım'
    },
    {
        id: 'saha-supervizorluk-ve-devreye-alma-test',
        name: 'Saha Supervisor Hizmetleri'
    },
]


const Cozumler = () => {
    const [filteredData, setFilteredData] = useState()
    const location = useLocation()

    const param = useParams()


    useEffect(() => {
        const newData = DATA.find((e) => e.id === param.id)
        console.log(newData.name)
        setFilteredData(newData)

    }, [param])

    return (
        <div className='  '>
            <div className='flex items-center w-full relative h-72 '>
                <motion.img className='w-full h-72 object-cover absolute' src='/image/banner/1.jpg' />
            </div>
            <div className='h-96 w-full bg-white  z-10 inner-container '>
                <h5 className='general-heading '> {filteredData && filteredData.name}</h5>




            </div>
        </div>
    )
}

export default Cozumler